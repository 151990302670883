html {
  height: 100%;
}

body {
  padding: 0;
  margin: 0px 0px 0px 0px;
  font-family: "Pontano Sans", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-display: swap;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #000000;
  background-image: linear-gradient(#3c3c3e, #171719, #000000);
}