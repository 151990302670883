.Navigation {
  color: #999;
  padding-top: 20px;
}

.Navigation a {
  color: #999;
  font-weight: bold;
  text-decoration: none;
}

.spacer {
  margin-left: 3px;
  margin-right: 3px;
}